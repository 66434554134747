<template>
  <div>
    <!-- TODO -->
  </div>
</template>
<script>
export default {
  created() {
    // eslint-disable-next-line
    window.alert('Pagina em desenvolvimento');
    this.$router.push('/home');
  },
};
</script>
